.storyboardSpace {
  display: grid;
  grid-template-columns: 10px minmax(0, 1fr) 85px;
  grid-template-rows: 40px minmax(0, 1fr) 100px;

  grid-template-areas:
    ".  mid-top  top-right"
    ".  main     mid-right"
    ".  main     .";

  color: white;
  background-color: rgb(27, 27, 27);
}

.midTop {
  grid-area: mid-top;
  text-align: left;
}

.midRight {
  grid-area: mid-right;
}

.main {
  grid-area: main;
}

.viewer {
  width: 600px;
}

.playViewContainer {
  width: 600px;
  height: 338px;
}

.dim {
  opacity: 0.5;
}
