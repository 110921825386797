.scoutFrameGrid {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 10px minmax(0, 1fr) 85px;
  grid-template-rows: 40px minmax(0, 1fr) 100px;

  grid-template-areas:
    ".  summary     knobs"
    ".  playframe   mid-right"
    ".  playframe   .";
  color: white;
  background-color: rgb(27, 27, 27);
  text-align: center;
}

.scoutFrameIPad {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 10px minmax(0, 1fr) 85px;
  grid-template-rows: 40px minmax(0, 1fr) 200px;

  grid-template-areas:
    ".  summary     knobs"
    ".  playframe   mid-right"
    ".  playframe   .";
  color: white;
  background-color: rgb(27, 27, 27);
  text-align: center;
}

.summary {
  grid-area: summary;
}

.knobs {
  grid-area: knobs;
  margin-top: 4px;
}

.midRight {
  grid-area: mid-right;
}

.playFrameContainer {
  grid-area: playframe;
}

.tips {
  grid-row: 3;
  grid-column: 3;
}

.tipsIPad {
  grid-row: 3;
  grid-column: 3;
  top: 10px;
}

.touchAction {
  touch-action: none;
}

.noUserSelect {
  user-select: none;
}
